import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/logos/logo.svg";
import "../../assets/css/style.css";
//import "@fortawesome/fontawesome-free/css/all.min.css";
import "../../assets/css/fontawesome.css";
import "../../assets/css/bootstrap.min.css";

const Header = () => {
  const [headerContent, setHeaderContent] = useState("");
  const [menuItems, setMenuItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    Promise.all([
      fetch("http://localhost/wp-headless/server/wp-json/custom/v1/header"),
      fetch("http://localhost/wp-headless/server/wp-json/menus/v1/menus"),
    ])
      .then(async ([headerRes, menuRes]) => {
        if (!headerRes.ok) throw new Error("Failed to fetch header");
        if (!menuRes.ok) throw new Error("Failed to fetch menus");

        const headerData = await headerRes.json();
        const menuData = await menuRes.json();

        setHeaderContent(headerData.content);
        if (menuData.length > 0 && menuData[0].items) {
          setMenuItems(menuData[0].items);
        }
      })
      .catch((err) => setError(err.message))
      .finally(() => setLoading(false));
  }, []);

  if (loading) return <header>Loading...</header>;
  if (error) return <header>Error: {error}</header>;

  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src={Logo} alt="Logo" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarNav"
          >
            <ul className="navbar-nav">
              {menuItems.length > 0 ? (
                menuItems.map((item) => (
                  <li key={item.ID} className="nav-item">
                    <Link
                      to={item.url}
                      className="nav-link"
                      aria-label={item.title}
                    >
                      {item.title}
                    </Link>
                  </li>
                ))
              ) : (
                <li className="nav-item">
                  <span className="nav-link disabled">Loading menu...</span>
                </li>
              )}
            </ul>
            <button type="button" className="rounded-pill btn-rounded">
              +1 728365413
              <span>
                <i className="fas fa-phone-alt"></i>
              </span>
            </button>
          </div>
        </div>
      </nav>
      <div dangerouslySetInnerHTML={{ __html: headerContent }} />
    </header>
  );
};

export default Header;
