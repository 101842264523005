// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "../src/assets/css/fontawesome.css";
import Home from "./components/pages/Home";

function App() {
  return (
    <Router>
      <Routes>
        {/* Define your routes here */}
        <Route path="/" element={<Home />} />
        {/* You can add more routes as needed */}
      </Routes>
    </Router>
  );
}

export default App;
