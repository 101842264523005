import React, { useState, useEffect } from "react";
import "../../assets/css/fontawesome.css";
import Header from "../common/Header";
import Footer from "../common/Footer";

function Home() {
  const [pageContent, setPageContent] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Replace with your actual REST API endpoint
    const fetchHomePage = async () => {
      try {
        const response = await fetch(
          "http://localhost/wp-headless/server/wp-json/wp/v2/pages?slug=home"
        );
        const data = await response.json();

        if (data.length > 0) {
          setPageContent(data[0]); // Get the first page matching the slug
        } else {
          console.error("Home page not found in WordPress.");
        }
      } catch (error) {
        console.error("Error fetching home page:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchHomePage();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!pageContent) {
    return <p>Home page content not found.</p>;
  }

  return (
    <div>
      <Header />
      <div dangerouslySetInnerHTML={{ __html: pageContent.content.rendered }} />
      <Footer />
    </div>
  );
}

export default Home;
