import React, { useEffect, useState } from "react";

const Footer = () => {
  const [footerContent, setFooterContent] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch the footer content from the WordPress REST API
    fetch("http://localhost/wp-headless/server/wp-json/custom/v1/footer")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch footer content");
        }
        return response.json();
      })
      .then((data) => {
        setFooterContent(data.content); // Assuming API returns { content: "<footer>...</footer>" }
        setLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <footer>Loading...</footer>; // Show a loading message while fetching
  }

  if (error) {
    return <footer>Error: {error}</footer>; // Show an error message if fetching fails
  }

  return <footer dangerouslySetInnerHTML={{ __html: footerContent }}></footer>;
};

export default Footer;
